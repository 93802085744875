@tailwind base;
@tailwind components;
@tailwind utilities;

.default-text-color {
  color: #311105;
}

body {
  @apply default-text-color;
  margin: 0;
  padding: 0;
  width: 100%; /* Use viewport width to ensure full width */
  height: 100%; /* Ensure it takes full viewport height */
  max-width: 100vw; /* Prevent any overflow beyond the viewport */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  /* @apply bg-gray-50; */
  background-color: #f2ece3;
}

/* In some cases, the default CSS margins and paddings applied by 
 * browsers can cause issues. Use a CSS reset at the beginning of your styles 
 * to ensure consistency across browsers. 
 *
 * By addressing these areas, you should be able to stop your site from 
 * scrolling down automatically when opened on a mobile device.
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply merriweather-text;
}

.app-container {
  @apply w-full max-w-full;
}

.dancing-text {
  font-weight: bold;
  font-family: "Dancing Script", cursive;
  letter-spacing: 1px;
}

.poppins-text {
  font-family: "Poppins", sans-serif;
}

.oswald-text {
  font-family: "Oswald", sans-serif; /* Tall and strong sans-serif font */
}

.playfair-text {
  font-family: "Playfair Display", serif;
}

.opensans-text {
  font-family: "Open Sans", sans-serif;
}

.robotoslab-text {
  font-family: "Roboto Slab", serif;
}

.merriweather-text {
  font-family: "Merriweather", serif;
}

.default-button {
  @apply text-gray-50 py-2 px-8 rounded-lg shadow;
  @apply bg-blue-500 hover:bg-blue-600;
  @apply cursor-pointer;
}

.logo {
  font-stretch: expanded; /* Make the text slightly taller */
  line-height: 1.5; /* Increase line height for taller appearance */
  display: flex;
  align-items: center; /* Vertically centers the text */
  justify-content: center; /* Horizontally centers the text (optional) */
  height: 50px; /* Set a specific height for the label */
  @apply min-w-max px-1;

  @apply playfair-text;
  font-size: 1rem; /* Large font size */
  color: #2c3e50; /* Dark slate color */
  text-align: center; /* Center the text */
  letter-spacing: 0.05rem; /* Slightly reduced spacing for a compact look */
  font-weight: 700; /* Bold for strong appearance */
  text-transform: uppercase; /* Make the text all uppercase for sharpness */
  background-color: #f7f7f7; /* Light background color */
  border-radius: 10px; /* Slightly rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  -webkit-font-smoothing: none; /* Disable font smoothing for sharper edges */
  -moz-osx-font-smoothing: grayscale; /* Set font smoothing to grayscale */
  @apply cursor-pointer whitespace-nowrap;
}

.homepage-container {
  @apply flex flex-col gap-16;
}

.homepage-section {
  @apply w-full h-full;
  @apply flex;
  @apply justify-center align-middle items-center;
}

.homepage-section-link {
  @apply flex w-min whitespace-nowrap merriweather-text underline-offset-8 text-lg tracking-tight;
  text-decoration: underline;
  transition: text-underline-offset 0.3s ease, color 0.3s ease;
}

.homepage-section-link:hover {
  text-underline-offset: 9.5px; /* Move underline down */
  color: #8b4513; /* Optional: Change color on hover */
}

.homepage-container-full-image {
  @apply w-full;
  /* height: 55vh; Sets the height to 100% of the viewport */
  @apply h-auto;
}

.homepage-boxes {
  @apply py-2;
  /* @apply grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4; */
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  gap: 20px; /* Adjust gap between grid items */
  justify-content: center; /* Horizontally center the grid */
  align-items: center; /* Vertically center the grid */
}

.homepage-productlist-boxes {
  @apply py-2 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6;
}

.header {
  @apply bg-gray-50;
  @apply p-3 shadow-md flex items-center justify-between;
  @apply sticky z-50 top-0;
}

.header-logo-background {
  position: relative;
  width: 200px; /* Set width of the container */
  height: 60px; /* Set height of the container */
  display: flex; /* To align foreground content */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent content overflow */
}

.header-logo-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../public/images/american-flag-background.webp") no-repeat
    center center;
  background-size: cover;
  opacity: 0.35; /* Adjust opacity of the background */
  z-index: 1; /* Place it behind the content */
  @apply rounded-2xl;
}

.header-logo-foreground {
  /* position: relative; */
  z-index: 2; /* Place the foreground content above the background */
  height: auto;
  /* width: 200px; Set size of the foreground image */
  width: auto;
  max-height: 27px;
  max-width: 285px;
  @apply pt-2 sm:pt-2 md:mt-2 lg:mt-2 xl:mt-2 2xl:mt-2;
  @apply sm:pl-8;
}

.product-card-image {
  @apply w-full h-full hover:opacity-75;
  @apply transition duration-75 ease-in-out object-fill;
}

.product-card-button {
  @apply bg-accent text-white rounded-lg px-4 py-2 shadow hover:bg-accent-light transition duration-200 ease-in-out;
}

/* src/index.css */
.tab-button {
  /* border: 1px solid #ddd; */
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  position: relative;
  @apply whitespace-nowrap;
  @apply min-w-24 h-full justify-center items-center;
}

.arrow-down {
  width: 0;
  height: 0;
  @apply ml-2;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 1000;
}

.tab-button:hover + .dropdown-content,
.dropdown-content:hover {
  display: block;
  @apply flex flex-col;
}

.dropdown-item {
  @apply text-sm px-4 py-2 whitespace-nowrap;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.tab-content {
  padding: 10px;
  border-top: 1px solid #ddd;
}

/* Cart badge */
.cart-badge {
  position: absolute;
  @apply -mt-4 -mr-5;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Makes it a circle */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.cart-remove-button {
  @apply text-sm;
  @apply bg-red-500 text-gray-50 py-2 px-4 rounded-lg shadow hover:bg-red-600;
  @apply absolute right-4;
}

.cart-add-button {
  @apply bg-green-900 hover:bg-green-800 text-white;
  @apply py-2 px-4 rounded-lg mt-4 disabled:opacity-50;
}

.cart-checkout-button {
  @apply default-button;
  @apply absolute bottom-4 left-1/2 transform -translate-x-1/2;
}

/* Styles for the Cart component */
.cart {
  @apply fixed top-0 right-0 w-full md:w-1/3 h-full;
  @apply bg-gray-100 shadow-lg z-50;
  @apply transform transition-transform duration-500 ease-in-out gap-4;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.overlay.open {
  display: block;
}

.remove-button {
  background-color: #f44336;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #d32f2f;
}

.hero {
  text-align: center;
  padding: 2rem;
  background-color: #fff;
  border-bottom: 2px solid #007bff;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.hero p {
  font-size: 1.25rem;
  color: #555;
}

.content {
  padding: 2rem;
}

.info {
  margin-bottom: 2rem;
}

.info h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.info p {
  font-size: 1rem;
  line-height: 1.5;
}

.team {
  display: flex;
  justify-content: space-between; /* Evenly distribute boxes */
  @apply gap-2;
  flex-wrap: wrap; /* Wrap to next line if needed */
}

.team-member {
  width: 200px; /* Fixed width for the boxes */
  @apply h-full;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  padding: 16px;
}

.team-member img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.team-member h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.team-member p {
  font-size: 1rem;
  color: #777;
}

.counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply bg-gray-100;
  @apply border border-gray-400 rounded-sm;
  @apply divide-x-2 divide-gray-400;
  @apply w-24;
}

.counter-button {
  cursor: pointer;
  @apply w-1/3;
  @apply text-center;
}

.counter-value {
  @apply text-lg font-bold;
  @apply w-1/3;
  @apply text-center;
}

.carousel {
  @apply w-full;
  height: 75vh; /* Sets the height to 100% of the viewport */
  @apply relative;
}

.carousel-image-full {
  @apply w-full h-full;
}

.carousel-image-scaled-down {
  @apply w-full h-full;
  @apply object-scale-down sm:object-scale-down md:object-scale-down lg:object-scale-down xl:object-contain;
  transition: opacity 1s ease-in-out;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;

  @apply bg-gray-50;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  @apply bg-transparent;
}

.carousel-center-button {
  position: absolute;
  bottom: 10%;
  left: 50%; /* Center the button horizontally */
  transform: translate(-50%, -50%); /* Offsets the button to the exact center */
  @apply w-40; /* Button takes the full width of the container */
  padding: 10px;
  text-align: center;
  cursor: pointer;
  @apply text-red-600 dancing-text;
  @apply rounded-full;
  @apply bg-gray-200;
  @apply border border-gray-400;
}

.carousel-center-button:hover {
  @apply text-red-700;
  @apply bg-white;
}

.carousel-button.prev {
  @apply left-4;
}

.carousel-button.prev:hover {
  /* @apply bg-gray-300; */
}

.carousel-button.prev:active {
  transform: translateX(1px) translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.carousel-button.next {
  @apply right-4;
}

.carousel-button.next:hover {
  /* @apply bg-gray-300; */
}

.carousel-button.next:active {
  transform: translateX(5px) translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.carousel-indicators {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.carousel-indicator {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin: 05px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel-indicator.active {
  background-color: white;
}

.product-page-container {
  display: grid;
  gap: 1.5rem;
  margin: 0 auto;
  padding: 1.5rem;
  @apply mx-auto p-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-8 gap-4;
}

.product-page-container img {
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.size-selector {
  display: flex;
  @apply flex-wrap;
  gap: 10px;
  @apply justify-center;
}

.size-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background-color: white;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

.size-button:hover {
  background-color: #f0f0f0;
}

.size-button.selected {
  @apply bg-yellow-800;
  color: white;
}

.card {
  @apply h-full w-full;
  @apply flex flex-col gap-2;
}

.card-content h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  @apply playfair-text text-center;
}

.card-content p {
  font-family: "Montserrat", sans-serif;
  color: #34495e;
  @apply px-1;
}

.contact-info {
  list-style: none;
  @apply pb-2;
}

.contact-info li {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  color: #34495e;
  margin-bottom: 10px;
  @apply px-8 min-w-min;
}

.contact-info a {
  @apply text-red-600;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-image {
  flex: 1;
  text-align: right;
}

.contact-image img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.theme-card-image {
  @apply rounded-b-lg rounded-t-none;
  @apply w-full h-40 object-fill;
}

.theme-card-button {
  position: absolute;
  @apply top-2/3;
  left: 50%; /* Center the button horizontally */
  transform: translate(-50%, -50%); /* Offsets the button to the exact center */
  @apply w-40; /* Button takes the full width of the container */
  padding: 10px;
  text-align: center;
  cursor: pointer;
  @apply text-red-600 dancing-text;
  @apply rounded-full;
  @apply bg-gray-200;
}

.theme-card-button:hover {
  @apply text-red-700;
  @apply bg-white;
}

.theme-card-header-button {
  @apply w-40; /* Button takes the full width of the container */
  text-align: center;
  cursor: pointer;
  @apply text-red-600 dancing-text;
  @apply rounded-full;
  @apply bg-gray-200;
  @apply text-lg my-2 pt-1.5;
}

.theme-card-header-button:hover {
  @apply text-red-700;
  @apply bg-white;
}

.theme-card-customplace-button {
  position: absolute;
  transform: translate(-50%, -50%); /* Offsets the button to the exact center */
  @apply w-40; /* Button takes the full width of the container */
  padding: 10px;
  text-align: center;
  cursor: pointer;
  @apply text-red-600 dancing-text;
  @apply rounded-full;
  @apply bg-gray-200;
}

.theme-card-customplace-button:hover {
  @apply text-red-700;
  @apply bg-white;
}

.search-bar {
  @apply mx-2 mb-2 mt-8;
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  border-radius: 5px;
  font-size: 1rem;
  @apply py-2 pl-10 pr-2 border rounded-lg text-black;
}

.search-menu {
  @apply fixed top-0 right-0 sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 h-full;
  @apply z-50 bg-gray-100 shadow-lg;
  @apply transform transition-transform duration-500 ease-in-out;
  @apply p-6 bg-white flex flex-col items-start text-left;
}

.search-menu-search-button {
  position: absolute;
  top: 10%;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #999;
  cursor: pointer;
}

.search-menu h2 {
  @apply text-xl font-bold;
}

.search-menu-filter-button {
  @apply border p-3;
}

.clear-button {
  position: absolute;
  top: 10%;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #999;
  cursor: pointer;
}

.clear-button:hover {
  color: #333;
}

.zoom-wrapper {
  display: flex;
  align-items: flex-start;
}

.image-container {
  position: relative;
  @apply w-full h-auto object-cover rounded-lg shadow-lg;
}

.product-image {
  width: 100%;
  height: auto;
}

.highlight-box {
  position: absolute;
  border: 2px solid blue;
  pointer-events: none; /* Prevent interaction with the highlight box */
  background-color: rgba(0, 0, 255, 0.1); /* Slightly transparent blue */
}

.zoom-overlay {
  @apply absolute right-1 z-50;
  width: 400px; /* Adjust to the zoomed image size */
  height: 400px;
  background-repeat: no-repeat;
  background-size: 200%; /* Zoom level */
  border: 1px solid #ddd;
  pointer-events: none; /* Prevent interaction with the zoom box */
}

/* Select Component */
.select {
  @apply border-b border-gray-300 rounded-md default-text-color focus:outline-none;
}

/* Hamburger Menu */
.hamburger-menu {
  @apply fixed top-0 left-0;
  @apply h-full z-50;
  @apply transform transition-transform duration-500 ease-in-out;
  @apply md:px-6 bg-white flex flex-col items-start text-left justify-between;
}

/* Subscribe Section */
.email-bar {
  position: relative;
  width: 100%;
}

.email-input {
  width: 100%;
  border-radius: 5px;
  font-size: 1rem;
  @apply p-2 text-black;
  @apply border-b-2 border-[#4a2c2a] outline-offset-8 bg-transparent focus:outline-none;
}

.enter-right-arrow-button {
  position: absolute;
  right: 5px;
  bottom: 2px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #999;
  cursor: pointer;
}
